
import PhotosPage from "./pages/Photos";

const navigation = [
  {
    title: 'Home',
    path: '/',
    component: PhotosPage
  },
  {
    title: "Interiors",
    path: "/interiors",
    component: PhotosPage
  },
  {
    title: "Exteriors",
    path: "/exteriors",
    component: PhotosPage
  },
  {
    title: "Published",
    path: "/published",
    component: PhotosPage
  },
  {
    title: "Still Life",
    path: "/still-life",
    component: PhotosPage
  },
  {
    title: "My Art",
    path: "/my-art",
    component: PhotosPage
  },
  {
    title: "About",
    path: "/about",
    component: PhotosPage
  }
];

export default navigation;
