import { Button, Container, Grid, Group, Modal, Text, Loader } from '@mantine/core';
import { useContext, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { BiImageAdd } from "react-icons/bi";
import { ImagesContext } from '../../providers/ImagesProvider';
import useLazyFetch from '../../hooks/useLazyFetch';
import { useNotifications } from '@mantine/notifications';
import { useLocation } from 'react-router-dom';

const baseUrl = 'https://willie-cole-photos.s3.amazonaws.com/'

function NewPhotoButton() {
  const [open, setOpen] = useState(false);
  const { images, setImages } = useContext(ImagesContext);
  const notifications = useNotifications();
  const pageName = useLocation().pathname.split('/')[1];

  const [makeFetch, { loading }] = useLazyFetch({
    onSuccess: (result) => {
      notifications.showNotification({
        title: `Photo Upload`,
        message: 'Photos were successfully uploaded!',
      })
      setOpen(false);
      if (images) {
        setImages({
          ...images,
          [pageName]: [
            ...images?.[pageName],
            ...result.data.map((elem: any, index: number) => ({ key: elem.key, url: baseUrl + elem.key, alt: index }))]
        })
      }
    },
    onError: (err) => {
      notifications.showNotification({
        title: 'Photo Upload',
        message: 'There was an error uploading the photos, please try again.',
        color: 'red'
      });
      console.log(err)
    }
  })

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="filled"
      >
        New Photo
      </Button>
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        title="Upload New Photos"
        size="lg"
      >
        {loading ? <Loader size={200} style={{
          width: '100%',
        }} /> : <Dropzone
          onDrop={(files) => {
            let formdata = new FormData();
            files.forEach((file, index) => {
              formdata.append(`image${index}`, file, `${file.name}`)
            })

            makeFetch({
              url: process.env.REACT_APP_STORAGE_URL + `upload?prefix=${pageName}`,
              options: {
                method: "POST",
                body: formdata
              }
            })
          }}
          onReject={() => { }}
          accept={['image/jpeg', 'image/png']}
        >
          {(status) => (
            <Group
              position='center'
              spacing="xl"
              style={{ minHeight: 220, pointerEvents: 'none' }}
              noWrap
            >
              <BiImageAdd style={{ height: 50, width: 50 }} />
              <div>
                <Text size="lg" inline>
                  Drag images here or click to select files
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Attach as many files as you'd like.
                </Text>
              </div>
            </Group>
          )}
        </Dropzone>}
      </Modal>
    </>
  )
}

function SaveButton() {
  const pageName = useLocation().pathname.split('/')[1];

  const notifications = useNotifications();
  const { images, deleted, setDeleted } = useContext(ImagesContext);

  const [makeFetch, { loading }] = useLazyFetch({
    onSuccess: () => {
      notifications.showNotification({
        title: `${pageName.charAt(0).toUpperCase() + pageName.slice(1)} Update`,
        message: 'Photos were successfully saved!',
      })
    },
    onError: (err) => {
      notifications.showNotification({
        title: 'Photo Update',
        message: 'There was an error saving photos, please try again.',
        color: 'red'
      })
    }
  })

  const [makeDeleteFetch] = useLazyFetch({
    onSuccess: () => {
    },
    onError: (err) => {
      notifications.showNotification({
        title: 'Photo Update',
        message: 'There was an error deleting photos, please try again.',
        color: 'red'
      })
    }
  })

  const onClick = async () => {
    if (deleted && deleted.length > 0) {
      await Promise.all(deleted.map(imageKey => {
        makeDeleteFetch({
          url: process.env.REACT_APP_STORAGE_URL + 'delete',
          options: {
            method: "DELETE",
            body: JSON.stringify({
              key: imageKey
            })
          }
        })
      }))
      setDeleted([])

    }
    if (images) {
      makeFetch({
        url: process.env.REACT_APP_STORAGE_URL + 'settings',
        options: {
          method: "POST",
          body: JSON.stringify(images)
        }
      })
    }

  }

  return (
    <Button
      variant="light"
      onClick={onClick}
    >
      {loading ? <Loader size="sm" /> : "Save & Publish"}
    </Button>
  )
}

function UtilityBar() {
  return (
    <Container padding="sm" size="xl">
      <Grid>
        <Grid.Col span={4}>
          <NewPhotoButton />
        </Grid.Col>
        <Grid.Col span={8}>
          <Group position="right">
            <SaveButton />
            {/* <Button variant="light">Publish</Button> */}
          </Group>
        </Grid.Col>
      </Grid>
    </Container>
  )
}

export default UtilityBar;