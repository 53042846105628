import { Image, Space, Button } from '@mantine/core';
import { useContext } from 'react';
import UtilityBar from '../components/UtilityBar/UtilityBar';
import { ImagesContext } from '../providers/ImagesProvider';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import LazyLoad from 'react-lazyload';

const rowHeight = 250

function GalleryImage({ blob, onDelete, id }: { blob: any, onDelete: Function, id: String }) {
  const url = blob ? blob : '';

  return (
    <LazyLoad height={rowHeight} style={{ margin: 10 }}>
      <div style={{ position: 'relative' }}>
        <Button
          color="red"
          size="sm"
          variant="light"
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
            zIndex: 10
          }}
          onClick={() => onDelete(id)}
        >
          Delete
        </Button>
        <Image
          fit="cover"
          height={rowHeight}
          width="100%"
          withPlaceholder
          radius={4}
          src={url}
          style={{
            display: 'block',
            pointerEvents: 'none',
          }}
        />
      </div>
    </LazyLoad>
  )
}

function PhotosPage({ title }: { title: string }) {
  const { images, setImages, deleted, setDeleted } = useContext(ImagesContext);
  const pageName = title.toLowerCase().split(' ').join('-');
  const pagePhotos = images?.[pageName] || [];

  const onChange = (sourceId: string, sourceIndex: number, targetIndex: number, targetId: string | undefined) => {
    const nextState = swap(pagePhotos, sourceIndex, targetIndex);
    return setImages({
      ...images,
      [pageName]: nextState
    });
  }

  const onDelete = (key: string) => {
    setImages({
      ...images,
      [pageName]: pagePhotos.filter((img) => {
        return img.key !== key
      })
    });
    setDeleted([...deleted, key])
  }

  let divHeight = ((1 + Math.floor(pagePhotos.length / 4)) * rowHeight);

  return (
    <>
      <UtilityBar />
      <Space h="lg" />
      <GridContextProvider onChange={onChange}>
        <div style={{
          margin: '10px',
          width: '100%',
          height: '100%'
        }}>
          {(pagePhotos.length > 0) && <GridDropZone
            id="imgs"
            boxesPerRow={4}
            rowHeight={250}
            style={{
              height: `${divHeight}px`,
              paddingBottom: 5
            }}
          >
            {pagePhotos.map(img => (
              <GridItem key={img.key}>
                <div
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <GalleryImage blob={img.url} onDelete={onDelete} id={img.key} />
                </div>
              </GridItem>
            ))}
          </GridDropZone>}
        </div>
      </GridContextProvider>
    </>
  );
}

export default PhotosPage;