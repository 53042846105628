import { useIdle, useLocalStorageValue } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import React, { createContext, useEffect, useState } from 'react';
import { useSessionstorage } from "rooks";

declare global {
  interface AuthenticationContextProps {
    isLoggedIn: Boolean;
    login: (u: string, p: string) => void;
    logout: () => void;
  }
}

export const AuthenticationContext = createContext<AuthenticationContextProps>({
  isLoggedIn: false,
  login: () => { },
  logout: () => { }
});

export default function AuthenticationProvider(props: {
  children: React.ReactNode;
}) {
  const idle = useIdle(3600000); // 1 hour
  const notifications = useNotifications();
  const [isLoggedIn, setIsLoggedIn, remove] = useSessionstorage('willie-cole-admin');

  const login = (u: string, p: string) => {
    if (p === process.env.REACT_APP_P && u === process.env.REACT_APP_U) {
      setIsLoggedIn(true)
    } else {
      notifications.showNotification({
        title: 'Whoops',
        color: 'red',
        message: 'Incorrect username or password, please try again.',
      })
    }
  };

  const logout = () => remove();

  return (
    <AuthenticationContext.Provider
      value={{
        isLoggedIn,
        login,
        logout
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  )
}