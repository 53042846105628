import { Link } from "react-router-dom";
import navigation from '../../navigation';
import { Anchor } from '@mantine/core';
import './Menu.scss'

const listStyle = {
  'margin': 0,
  'padding': '10px 30px 10px 10px',
  borderBottom: '1px solid rgba(25, 25, 25, 0.2)',
}

function Navigation() {
  return (
    <ul style={{ listStyle: 'none' }}>
      {navigation.map((page, index) => {
        const { title, path } = page;

        return (
          <li key={`menu-item-${index}`} style={listStyle} className={'list'}>
            <Anchor component={Link} to={path} style={{ textDecoration: 'none' }} className={'link'}>
              {title}
            </Anchor>
          </li>
        )
      })}
    </ul>
  )
}

export default Navigation;