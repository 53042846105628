import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { NotificationsProvider } from '@mantine/notifications';
import { BrowserRouter } from 'react-router-dom';
import AuthenticationProvider from './providers/AuthenticationProvider';
import ImagesProvider from './providers/ImagesProvider';

function Providers(props: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <NotificationsProvider position={'top-right'}>
        <AuthenticationProvider>
          <ImagesProvider>
            {props.children}
          </ImagesProvider>
        </AuthenticationProvider>
      </NotificationsProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
