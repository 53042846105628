import React from 'react';
import { Navbar } from '@mantine/core';
import Menu from '../Menu/Menu';
import { useViewportSize } from '@mantine/hooks';

function Sidebar() {
  const { height } = useViewportSize();

  return (
    <Navbar width={{ base: 200 }} height={height - 60} padding="xs">
      <Navbar.Section grow mt="lg"><Menu /></Navbar.Section>
    </Navbar>
  )
}

export default Sidebar;