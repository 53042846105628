function Brand() {
  return (
    <div>
      <svg width="270" height="40" viewBox="0 0 2365 353" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1572 83.0081H1611.85L1624.46 85.5283C1628.73 86.8045 1632.81 88.6707 1636.57 91.0729C1640.73 93.7022 1644.33 97.1284 1647.16 101.154C1650.58 106.06 1653.14 111.515 1654.73 117.284C1656.8 124.835 1657.82 132.638 1657.75 140.47V147.023C1657.92 155.661 1657.07 164.289 1655.23 172.729C1653.74 178.919 1651.36 184.861 1648.17 190.371C1645.58 194.846 1642.15 198.782 1638.08 201.964C1634.57 204.726 1630.66 206.936 1626.48 208.517C1622.58 210.003 1618.51 211.018 1614.37 211.541H1600.25V268.499H1572V83.0081ZM1601.76 191.883C1606.22 192.111 1610.69 191.425 1614.88 189.867C1618.38 188.439 1621.38 185.978 1623.45 182.81C1625.75 179.119 1627.3 175.007 1627.99 170.713C1629.04 164.722 1629.54 158.649 1629.5 152.567V135.933C1629.62 131.208 1629.28 126.482 1628.5 121.82C1627.82 118.143 1626.26 114.684 1623.96 111.739C1621.76 108.923 1618.77 106.819 1615.38 105.69C1611.16 104.272 1606.72 103.59 1602.27 103.674H1600.75V191.883H1601.76Z" fill="#5D5C5C" />
        <path d="M1746.28 83.0081H1774.53V161.136H1807.82V83.0081H1836.07V269.507H1808.33V183.314H1774.53V269.507H1746.28V83.0081Z" fill="#5D5C5C" />
        <path d="M1977.06 271.019H1967.48C1963.82 270.533 1960.25 269.514 1956.88 267.995C1948.98 264.374 1942.57 258.144 1938.72 250.353C1935.93 244.947 1933.89 239.18 1932.67 233.215C1931.15 225.244 1930.47 217.134 1930.65 209.021V142.99C1930.44 134.876 1931.12 126.763 1932.67 118.796C1933.92 113.001 1935.96 107.405 1938.72 102.162C1940.86 97.9575 1943.76 94.1946 1947.3 91.0729C1950.32 88.5294 1953.72 86.4871 1957.39 85.0242L1967.48 82H1986.14L1996.23 84.5203C2004.06 87.7447 2010.49 93.6381 2014.39 101.154C2017.2 106.555 2019.24 112.324 2020.44 118.292C2021.97 126.262 2022.65 134.373 2022.46 142.486V208.517C2022.5 216.482 2021.65 224.428 2019.94 232.207C2018.69 238.163 2016.65 243.926 2013.88 249.345C2011.72 253.531 2008.82 257.288 2005.31 260.434C2002.27 263.081 1998.87 265.287 1995.22 266.987C1992.02 268.466 1988.62 269.485 1985.13 270.011L1977.06 271.019ZM1977.06 249.849C1979.49 249.852 1981.9 249.336 1984.12 248.337C1986.29 247.228 1988.06 245.464 1989.17 243.296C1990.68 240.462 1991.7 237.395 1992.19 234.223C1992.96 229.39 1993.29 224.499 1993.2 219.606V134.925C1993.29 129.866 1992.95 124.807 1992.19 119.804C1991.71 116.469 1990.68 113.235 1989.17 110.227C1988.03 108.078 1986.27 106.322 1984.12 105.186C1981.91 104.156 1979.5 103.639 1977.06 103.674C1974.45 103.579 1971.86 104.097 1969.49 105.186C1967.34 106.322 1965.59 108.078 1964.45 110.227C1962.91 113.228 1961.89 116.465 1961.42 119.804C1960.66 124.807 1960.32 129.865 1960.41 134.925V219.606C1960.32 224.499 1960.66 229.39 1961.42 234.223C1961.9 237.4 1962.92 240.47 1964.45 243.296C1965.56 245.464 1967.32 247.228 1969.49 248.337C1971.88 249.377 1974.46 249.892 1977.06 249.849Z" fill="#5D5C5C" />
        <path d="M2133.69 105.186H2103.42V83.0081H2191.7V105.186H2161.94V269.003H2133.69V105.186Z" fill="#5D5C5C" />
        <path d="M2319.57 271.019H2309.99C2306.33 270.533 2302.76 269.514 2299.39 267.995C2291.41 264.247 2284.99 257.828 2281.23 249.849C2278.44 244.443 2276.4 238.675 2275.18 232.711C2273.66 224.74 2272.98 216.63 2273.16 208.517V142.99C2272.95 134.876 2273.63 126.763 2275.18 118.796C2276.43 113.001 2278.47 107.405 2281.23 102.162C2283.37 97.9575 2286.28 94.1946 2289.81 91.0729C2292.83 88.5294 2296.23 86.4871 2299.9 85.0242L2309.99 82H2328.65L2338.74 84.5203C2346.57 87.7447 2353 93.6381 2356.9 101.154C2359.71 106.555 2361.75 112.324 2362.95 118.292C2364.48 126.262 2365.16 134.373 2364.97 142.486V208.517C2365.01 216.482 2364.16 224.428 2362.45 232.207C2361.2 238.163 2359.16 243.926 2356.39 249.345C2354.23 253.531 2351.33 257.288 2347.82 260.434C2344.78 263.081 2341.38 265.287 2337.73 266.987C2334.53 268.466 2331.13 269.485 2327.64 270.011L2319.57 271.019ZM2319.57 249.849C2322.01 249.852 2324.41 249.336 2326.63 248.337C2328.8 247.228 2330.57 245.464 2331.68 243.296C2333.19 240.462 2334.21 237.395 2334.7 234.223C2335.47 229.39 2335.8 224.499 2335.71 219.606V134.925C2335.8 129.866 2335.46 124.807 2334.7 119.804C2334.22 116.469 2333.2 113.235 2331.68 110.227C2330.54 108.078 2328.78 106.322 2326.63 105.186C2324.42 104.156 2322.01 103.639 2319.57 103.674C2316.97 103.579 2314.37 104.097 2312 105.186C2309.85 106.322 2308.1 108.078 2306.96 110.227C2305.42 113.228 2304.4 116.465 2303.93 119.804C2303.17 124.807 2302.83 129.865 2302.93 134.925V219.606C2302.83 224.499 2303.17 229.39 2303.93 234.223C2304.41 237.4 2305.43 240.47 2306.96 243.296C2308.07 245.464 2309.84 247.228 2312 248.337C2314.39 249.377 2316.97 249.892 2319.57 249.849V249.849Z" fill="#5D5C5C" />
        <path d="M1480 0L1480 353L1496 353L1496 -6.99383e-07L1480 0Z" fill="#808080" />
        <path d="M917.174 315.647C912.488 315.597 907.811 315.22 903.178 314.519C898.021 313.734 893.004 312.214 888.279 310.008C883.074 307.608 878.276 304.412 874.057 300.534C869.132 295.876 865.08 290.375 862.093 284.292C858.208 276.354 855.399 267.933 853.741 259.253C851.419 247.669 850.284 235.878 850.354 224.064V127.067C850.152 115.183 851.211 103.312 853.515 91.6518C855.246 83.0334 858.132 74.6873 862.093 66.8386C865.119 60.7858 869.254 55.3534 874.283 50.8229C878.53 47.1014 883.327 44.0579 888.505 41.7999C893.19 39.7904 898.126 38.4245 903.178 37.7396C907.668 37.1452 912.193 36.8438 916.722 36.8373C926.034 36.55 935.313 38.0825 944.037 41.3488C950.822 44.0038 956.863 48.2605 961.645 53.7553C966.136 59.1437 969.375 65.4615 971.126 72.2524C973.006 79.622 973.916 87.205 973.835 94.8099C973.85 100.237 973.549 105.66 972.932 111.051C972.33 116.014 971.427 121.503 970.223 127.518H937.039V113.532C937.039 98.4942 935.384 87.2155 932.073 79.6964C928.762 72.1772 923.871 68.4177 917.4 68.4177C913.88 68.334 910.394 69.1082 907.241 70.6734C904.106 72.4329 901.585 75.1094 900.017 78.3429C897.771 82.8121 896.247 87.6086 895.503 92.5541C894.37 99.7913 893.842 107.11 893.922 114.435V239.628C893.808 246.789 894.185 253.949 895.051 261.058C895.555 265.754 896.852 270.33 898.889 274.592C900.458 277.712 902.991 280.243 906.112 281.811C909.43 283.364 913.06 284.136 916.722 284.067C919.387 284.077 922.017 283.459 924.397 282.262C927.035 280.884 929.286 278.867 930.944 276.397C933.009 273.373 934.537 270.015 935.459 266.472C936.625 261.828 937.157 257.047 937.039 252.261V225.643H975.641V250.907C975.641 273.013 970.599 289.255 960.516 299.631C950.433 310.008 935.986 315.346 917.174 315.647Z" fill="#808080" />
        <path d="M1069.1 315.647C1064.34 315.588 1059.59 315.211 1054.88 314.519C1049.57 313.764 1044.4 312.244 1039.53 310.008C1027.86 304.689 1018.38 295.532 1012.66 284.066C1008.67 276.152 1005.78 267.727 1004.08 259.028C1001.79 247.366 1000.73 235.496 1000.92 223.613V126.616C1000.77 114.723 1001.9 102.848 1004.31 91.2005C1006.08 82.5627 1009.05 74.2122 1013.11 66.3874C1016.23 60.3327 1020.44 54.9037 1025.53 50.3716C1029.91 46.6073 1034.86 43.5618 1040.2 41.3486C1045.04 39.3387 1050.13 37.9736 1055.33 37.2883C1059.97 36.6932 1064.64 36.3918 1069.32 36.386C1073.85 36.4239 1078.37 36.7252 1082.87 37.2883C1087.98 37.9129 1092.99 39.202 1097.77 41.1231C1103.01 43.2847 1107.89 46.2531 1112.21 49.9204C1117.39 54.3362 1121.68 59.6946 1124.86 65.7106C1128.92 73.5366 1131.88 81.8868 1133.66 90.5238C1136.07 102.324 1137.2 114.348 1137.05 126.39V223.387C1137.22 235.131 1136.08 246.858 1133.66 258.351C1131.83 266.972 1128.87 275.315 1124.86 283.164C1121.69 289.382 1117.4 294.964 1112.21 299.631C1107.8 303.539 1102.86 306.805 1097.54 309.331C1092.81 311.532 1087.8 313.051 1082.64 313.842C1078.18 314.786 1073.65 315.39 1069.1 315.647V315.647ZM1069.1 284.292C1072.74 284.38 1076.36 283.688 1079.71 282.262C1082.97 280.733 1085.66 278.204 1087.38 275.044C1089.64 270.819 1091.17 266.243 1091.9 261.509C1093.04 254.5 1093.56 247.405 1093.48 240.305V115.563C1093.56 108.238 1093.03 100.919 1091.9 93.6819C1091.18 88.7301 1089.65 83.9294 1087.38 79.4707C1085.76 76.2041 1083.15 73.5239 1079.93 71.8011C1076.62 70.2476 1072.99 69.4758 1069.32 69.5454C1065.59 69.4731 1061.88 70.2442 1058.49 71.8011C1055.19 73.4986 1052.51 76.1794 1050.81 79.4707C1048.51 83.9182 1046.99 88.7236 1046.3 93.6819C1045.17 100.919 1044.64 108.238 1044.72 115.563V240.305C1044.63 247.405 1045.16 254.5 1046.3 261.509C1047 266.249 1048.53 270.83 1050.81 275.044C1052.55 278.195 1055.23 280.72 1058.49 282.262C1061.85 283.662 1065.46 284.353 1069.1 284.292V284.292Z" fill="#808080" />
        <path d="M1176.1 39.0928H1217.63V280.457H1274.3V313.165H1176.1V39.0928Z" fill="#808080" />
        <path d="M1300.71 39.0928H1402.29V71.801H1342.24V153.91H1388.75V186.618H1342.24V280.457H1403.87V313.165H1300.71V39.0928Z" fill="#808080" />
        <path d="M0 39H40.8099L59.7457 207.665L62.0311 247.14H66.2753L69.5401 202.772L99.9027 39H133.53L162.26 202.772L165.525 247.14H169.769L171.728 207.665L189.032 39H229.842L190.664 318.26H147.895L124.715 184.176L117.533 125.779H114.268L107.085 184.176L82.9258 318.26H40.4835L0 39Z" fill="#BFBEBE" />
        <path d="M259.878 39H302.32V318.26H259.878V39Z" fill="#BFBEBE" />
        <path d="M349.986 39H392.428V284.984H450.215V318.26H349.986V39Z" fill="#BFBEBE" />
        <path d="M476.987 39H519.429V284.984H577.216V318.26H476.987V39Z" fill="#BFBEBE" />
        <path d="M603.987 39H646.429V318.26H603.987V39Z" fill="#BFBEBE" />
        <path d="M694.095 39H797.589V72.2763H736.538V156.12H783.877V189.396H736.538V284.984H799.222V318.26H694.095V39Z" fill="#BFBEBE" />
      </svg>
    </div>
  );
}

export default Brand;