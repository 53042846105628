import { Button, Container, PasswordInput, TextInput } from "@mantine/core";
import { useContext, useState } from "react"
import { AuthenticationContext } from "../providers/AuthenticationProvider"
import Brand from '../components/Brand/Brand';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { login } = useContext(AuthenticationContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Container
      size="xs"
      style={{
        position: 'fixed',
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
        <Brand />
      </div>
      <TextInput
        placeholder="Username"
        value={username}
        onChange={(event) => setUsername(event.currentTarget.value)}

      />
      <PasswordInput
        style={{ marginTop: 20 }}
        placeholder="Password"
        value={password}
        onChange={(event) => setPassword(event.currentTarget.value)}
      />
      <Button
        style={{ marginTop: 20 }}
        onClick={() => login(username, password)}>
        Login
      </Button>
    </Container>
  )
}