import React, { createContext, useEffect, useState } from 'react';
import useLazyFetch from '../hooks/useLazyFetch';

declare global {
  interface ImagesContextProps {
    edit: boolean;
    setEdit: (state: boolean) => void;
    images: Images | null;
    setImages: (settings: Images) => void;
    deleted: string[];
    setDeleted: (key: string[]) => void;
  }

  interface Image {
    alt: string;
    key: string;
    url: string;
  }

  interface Images {
    [page: string]: Image[];
  }
}

export const ImagesContext = createContext<ImagesContextProps>({
  edit: false,
  setEdit: () => { },
  images: null,
  setImages: () => { },
  deleted: [],
  setDeleted: () => { }
});

export default function ImagesProvider(props: {
  children: React.ReactNode;
}) {
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState<Images | null>(null);
  const [deleted, setDeleted] = useState<string[]>([])


  const [makeFetch] = useLazyFetch({
    onSuccess: (result) => {
      setImages(result.data)
    },

    onError: () => {
      setImages(null)
    }
  });

  useEffect(() => {
    makeFetch({
      url: process.env.REACT_APP_STORAGE_URL + 'settings'
    })
  }, [])

  return (
    <ImagesContext.Provider
      value={{
        edit,
        setEdit,
        images,
        setImages,
        deleted,
        setDeleted
      }}
    >
      {props.children}
    </ImagesContext.Provider>
  )
}