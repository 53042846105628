import { useContext } from 'react';
import { AppShell, Header } from '@mantine/core';
import Sidebar from './components/Sidebar/Sidebar';
import Brand from './components/Brand/Brand';
import {
  Routes,
  Route
} from "react-router-dom";
import navigation from './navigation';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './App.css'
import { AuthenticationContext } from './providers/AuthenticationProvider';
import Authentication from './pages/Authentication';

function App() {
  const { isLoggedIn } = useContext(AuthenticationContext);

  if (!isLoggedIn) {
    return <Authentication />
  }

  return (
    <AppShell
      padding="md"
      navbar={<Sidebar />}
      header={<Header height={60} padding="xs">{<Brand />}</Header>}
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          width: '100%'
        },
      })}
    >
      <DndProvider backend={HTML5Backend}>
        <Routes>
          {navigation.map(({ path, title, component: Component }, index) => (
            <Route key={`route-${index}`} path={path} element={<Component title={title} />} />
          ))}
        </Routes>
      </DndProvider>
    </AppShell>
  );
}

export default App;
